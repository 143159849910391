<template>
  <div class="flex flex-col justify-center min-h-screen h-full align-middle relative bg-blue-100 -mx-8" id="advantages">
    <div class="my-64 px-8 flex flex-wrap justify-evenly items-center content-center h-screen relative">
      <div class="flex flex-col justify-items-center items-center content-center relative mt-24">
        <div class="rounded-full opacity-50 w-64 h-64 bg-gradient-to-r from-blue-200 absolute top-12 left-0 top-0 z-0 -ml-16"></div>
        <h3 class="text-3xl z-20 text-secondary font-bold self-start">Voordelen voor het park</h3>
        <hr class="border-primary w-24 text-right mb-6 border-2 rounded-sm self-end bg-primary">
        <ul class="z-20 text-sm">
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div>Hoge <b>klanttevredenheid</b> en parkwaardering</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div>Extra <b>communicatiekanaal</b> naar bezoekers toe</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div>Toegang tot statistische <b>gegevens</b> o.b.v. locatiebepaling</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div><b>Minimale ondersteuning</b> door personeel noodzakelijk</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div><b>Spreiding</b> van bezoekers over heel het park</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-primary rounded-full"></div><b>Geopositioning</b> laat snelle actie toe bij noodgevallen</li>
        </ul>
      </div>

      <div class="flex flex-col justify-items-center items-center content-center relative mt-24">
        <div class="rounded-full opacity-50 w-64 h-64 bg-gradient-to-r from-blue-200 absolute top-12 left-0 top-0 z-0 -ml-16"></div>
        <h3 class="text-3xl z-20 text-secondary self-start font-bold">Voordelen voor de bezoeker</h3>
        <hr class="border-blue-300 w-24 text-right mb-6 border-2 rounded-sm self-end bg-blue-400">
        <ul class="z-20 text-sm">
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div><b>Meer tijd en ruimte</b> om heel het park te bezoeken</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div><b>Aanschuiven</b> kan <b>vanop elke plek</b> in het park</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div><b>Extra informatiekanaal</b> beschikbaar in meerdere talen</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div><b>Helder</b> en <b>eenvoudig</b> in gebruik</li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div>Spreekt aan tot het <b>rechtvaardigheidsgevoel</b></li>
          <li class="mb-2"><div class="inline-block mr-2 h-2 w-2 bg-blue-400 rounded-full"></div><b>Direct communicatiekanaal</b> met het park management</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
