<template>
    <div class="">
<!--        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet">-->
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
        <div class="p-8 h-full">
              <Navbar />
              <Header />
        </div>

            <img
                src="@/assets/img/Landing/Divider.svg"
                class="h-32 w-full -mt-32 z-50 relative"
            />

            <div class="p-8">
              <WhoAreWe class="bg-white"/>
              <StandFor class="bg-white"/>
              <WhatWeDo class="bg-white"/>
              <Pricing class="bg-white"/>
            </div>

            <Workings  class="bg-white"/>

            <img
                src="@/assets/img/Landing/bottom_divider.svg"
                class="h-32 w-full -mb-32 z-50 relative"
            />
        <div class="p-8 relative">
            <Advantages />

            <img
                src="@/assets/img/Landing/footer_cars.svg"
                class="absolute bottom-0 -mx-8 w-full"
            />
        </div>

        <div class="p-8 relative z-4">
            <Footer class="bg-white"/>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Landing/Navbar'
import Header from '@/components/Landing/Header'
import Workings from '@/components/Landing/Workings'
import Advantages from '@/components/Landing/Advantages'
import Footer from '@/components/Landing/Footer'
import WhoAreWe from '@/components/Landing/WhoAreWe'
import StandFor from '@/components/Landing/StandFor'
import WhatWeDo from "../../components/Landing/WhatWeDo.vue"
import Pricing from "../../components/Landing/Pricing.vue"
import SmoothScroll from 'smooth-scroll'

export default {
    components: {
      Pricing,
      WhatWeDo,
      Navbar,
      Header,
      Workings,
      Advantages,
      Footer,
      WhoAreWe,
      StandFor
    },

    mounted() {
      new SmoothScroll('a[href*="#"]');
    }
}
</script>

<style scoped>
  body {
    font-family: 'Montserrat', sans-serif !important;
  }
</style>
