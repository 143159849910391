<template>
  <div class="font-sans antialiased relative z-50">
    <nav class="flex items-center justify-between flex-wrap bg-white lg:p-6 lg:p-2">
      <div class="flex items-center flex-no-shrink text-white mr-6">
        <img src="@/assets/svg/logo.svg" alt="logo virtua theme park" class="rounded-full w-24 h-24 mx-4"/>
         <div>
          <p class="text-primary w-full text-2xl uppercase font-bold">Virtua</p>
          <p class="text-secondary text-xl uppercase font-bold">Theme Park</p>
        </div>
      </div>
      <div class="block lg:hidden">
        <button
          @click="toggle"
          class="flex items-center px-3 py-2 border rounded text-gray-700 border-teal-light hover:text-secondary hover:border-secondary"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="open ? 'block' : 'hidden'"
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto"
      >
        <div class="lg:flex-grow items-end text-right justify-evenly">
<!--          <a
            href="#home"
            class="no-underline block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-secondary mr-8 cursor-pointer"
          >
            Virtua Theme Park
          </a>-->

          <a
            href="#stand-for"
            data-scroll
            class="no-underline block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-secondary mr-8 cursor-pointer"
          >
            Waar staan we voor
          </a>

          <a
            href="#what-we-do"
            data-scroll
            class="no-underline block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-secondary mr-8 cursor-pointer"
          >
            Wat doen we
          </a>

          <a
            href="#pricing"
            data-scroll
            class="no-underline block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-secondary mr-8 cursor-pointer"
          >
            Wat kost het
          </a>

          <a
            href="#how-it-works"
            data-scroll
            class="no-underline block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-secondary mr-8 cursor-pointer"
          >
            Hoe werkt het?
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
